@import '~@/assets/scss/main.scss';

#student-form {
    width: 30vw;

    // hides the default label from val-form-input and shows the label from this component
    label[for="allowEmailStudents"] {
        display: none !important;
    }
    #allowEmailStudents + label[for="allowEmailStudents"] {
        display: block !important;
    }

    @include media-breakpoint-down(lg) {
        width: 40vw;
    }

    @include media-breakpoint-down(md) {
        width: 75vw;
    }
}

.form-group.required label {
    &::before {
        content: "* ";
        font-weight: bold;
        color: red;
    }
}
